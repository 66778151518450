import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, cloneDeep as _cloneDeep, map as _map, includes as _includes } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { matchPath, Link } from "react-router-dom";
import { connect } from "react-redux";
// import moment from "moment";

import { EmptyLabel, RenderTableCellValue, RenderTableHeaderValue, showAlertMessage, ConfirmBox, ShowContentLoader } from "../../resusableComponents/common/formElements";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { withRouter } from "../../resusableComponents/hoc/withRouter";
import { LeadStatusWithAttributes, VIEW_PENDING_LEADS_ROUTE, VIEW_APPROVED_LEADS_ROUTE, VIEW_REJECTED_LEADS_ROUTE } from "../../helpers/constants";
import { downloadExcelFile } from "../../helpers/utils";
import ReactDataTable from "../../resusableComponents/common/ReactDataTable";
import UpdateLeadColumn from "./components/UpdateLeadColumn";
import RejectLeadModal from "./components/RejectLeadModal";
import LeadFilters from "./components/LeadFilters";
import PipeToCRM from "./components/PipeToCRM";

import { updateLeadStatus, exportSelectedLeads } from "../../redux/services/campaign";
import { fetchCampaignLeadDetails, resetCampaignLeadDetails } from "../../redux/actions/campaign";
import { updateLoadingState } from "../../redux/actions/application";

const defaultLeadsFilter = { matchKey: "", approval: [], exported: null, startDate: null, showStartDate: null, endDate: null, showEndDate: null, columnObj: null, columnId: "", columnValues: "", rejectionValues: [], uploadIds: [], rejectionPayloadValues: [], assetIds: [], sortColumn: "lead_id", sortOrder: "ASC", piped: null, pageNo: 1, pageSize: 99999 };

class ViewLeads extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;
    this.timer = null;

    this.defaultFilterAccordion = {
      showApprovalSection: false,
      showAssetsSection: false,
      showUploadIdsSection: false
    }

    this.defaultChangeStatusState = {
      currentLeadsStatus: null,
      rejectionReason: "",
      rejectionReasonType: null,
      showStatusChangeModal: false,
      showLeadRejectReasonModal: false
    }

    this.state = {
      campaignId: parseInt(_get(this.props, "params.id", 0)),
      campaignDetails: {},
      paginationInfo: {},
      leadsList: [],
      reloadTable: false,
      selectedLeadIds: [],
      selectAllLeads: false,
      leadsFilter: _cloneDeep(defaultLeadsFilter),
      showLeadFilterModal: false,
      pendingLeadsView: false,
      approvedLeadView: false,
      rejectedLeadsView: false,
      disableApprovalFilter: false,
      filterAccordian: { ...this.defaultFilterAccordion },
      filterSelectedColumnId: null,
      changeStatusState: { ...this.defaultChangeStatusState },
      CRMList: [],
      knobData: {},
      selectedRows: [],
      showSelectAllModal: false,
      selectAllOrCurrent: null,
      showLeadsTableLoader: true
    };

    this.hasPendingLeadsAccess = hasAccess("campaigns", "pending-leads", ["page-view"]);
    this.hasApproveLeadsAccess = hasAccess("campaigns", "approved-leads", ["page-view"]);
    this.hasRejectedLeadsAccess = hasAccess("campaigns", "rejected-leads", ["page-view"]);

    this.tableColumns = [
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.lead_id", defaultMessage: "Lead ID" })} />),
        selector: (row) => _get(row, "lead_id", ""),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "lead_id", "")} tooltip={true} />),
        sortable: true,
        width: "90px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.date", defaultMessage: "Date" })} />),
        selector: (row) => (_get(row, "date", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "date", "")} tooltip={true} />),
        sortable: true,
        width: "100px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.company", defaultMessage: "Company" })} />),
        selector: (row) => (_get(row, "company", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "company", "")} tooltip={true} />),
        sortable: true
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.name", defaultMessage: "Name" })} />),
        selector: (row) => (_get(row, "name", "")),
        cell: (row) => {
          if (!_isEmpty(_get(row, "personality_card_url", null))) {
            return (
              <a href={_get(row, "personality_card_url", "")} className="text-blue-22" target="_blank" rel="noreferrer">
                {_get(row, "name", "")}
              </a>
            )
          } else {
            return (<RenderTableCellValue className="text-truncate" value={_get(row, "name", "")} tooltip={true} />)
          }
        },
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.email", defaultMessage: "email" })} />),
        selector: (row) => (_get(row, "email", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "email", "")} tooltip={true} />),
        sortable: true,
        width: "150px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.job_title", defaultMessage: "Job Title" })} />),
        selector: (row) => (_get(row, "job_title", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "job_title", "")} tooltip={true} />),
        sortable: true,
        width: "100px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.qa_delivery", defaultMessage: "QA Delivery" })} />),
        selector: (row) => (_get(row, "delivery_status", "")),
        cell: (row) => (<>{this._renderQAStatus(row, "delivery_status", 1)}</>),
        sortable: true,
        width: "120px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.qa_criteria", defaultMessage: "QA Criteria" })} />),
        selector: (row) => (_get(row, "criteria_status", "")),
        cell: (row) => (<>{this._renderQAStatus(row, "criteria_status")}</>),
        sortable: true,
        width: "120px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.approved", defaultMessage: "Approved" })} />),
        selector: (row) => (_get(row, "approval_status", "")),
        cell: (row) => (<>{this._renderApprovedStatus(row, "approval_status")}</>),
        sortable: true,
        width: "120px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "view_leads.table.card_status", defaultMessage: "Card status" })} />),
        selector: (row) => (_get(row, "humantics_flag", "")),
        cell: (row) => (<RenderTableCellValue className={`text-truncate badge ${_get(row, "humantics_status_color_code", "")}`} value={_get(row, "humantics_flag", "")} tooltip={true} altToolText={_get(row, "humantics_status_description", "")} />),
        sortable: true,
        width: "120px"
      },
    ];
  }

  componentDidMount() {
    const { location } = this.props;
    const { leadsFilter } = this.state;

    const pathName = _get(location, "pathname", "");
    let tmpLeadFilter = _cloneDeep(leadsFilter);

    const pendingLeadsView = matchPath({ path: `/${VIEW_PENDING_LEADS_ROUTE}`, exact: true, strict: true }, pathName);
    const approvedLeadView = matchPath({ path: `/${VIEW_APPROVED_LEADS_ROUTE}`, exact: true, strict: true }, pathName);
    const rejectedLeadsView = matchPath({ path: `/${VIEW_REJECTED_LEADS_ROUTE}`, exact: true, strict: true }, pathName);

    let updateState = {};

    if (pendingLeadsView !== null) {
      tmpLeadFilter = { ...tmpLeadFilter, approval: [0] };
      updateState = { leadsFilter: tmpLeadFilter, pendingLeadsView: true, disableApprovalFilter: true };
    }

    if (approvedLeadView !== null) {
      tmpLeadFilter = { ...tmpLeadFilter, approval: [1] };
      updateState = { leadsFilter: tmpLeadFilter, approvedLeadView: true, disableApprovalFilter: true };
    }

    if (rejectedLeadsView !== null) {
      tmpLeadFilter = { ...tmpLeadFilter, approval: [2] };
      updateState = { leadsFilter: tmpLeadFilter, rejectedLeadsView: true, disableApprovalFilter: true };
    }

    if (!_isEmpty(updateState)) {
      this.setState(updateState, () => this._fetchCampaignLeadDetails());
    } else {
      this._fetchCampaignLeadDetails();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!_isEqual(prevState.leadsFilter, this.state.leadsFilter) && !_isEmpty(this.state.leadsFilter)) ||
      (!_isEqual(prevState.reloadTable, this.state.reloadTable) && (_get(this.state, "reloadTable", false) === true))
    ) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this._fetchCampaignLeadDetails();
      }, 500);
    }

    if (!_isEqual(prevProps.leads, this.props.leads) && !_isEmpty(this.props.leads)) {
      const { leads, resetCampaignLeadDetails } = this.props;
      const responseFlag = _get(leads, "flag", null);

      if (responseFlag === null) { return false; }

      if (responseFlag === true) {
        this._setLeadListData();
      } else if (responseFlag === false) {

        this.setState({ showLeadsTableLoader: false })
        showAlertMessage(_get(leads, "message", "Something went wrong while fetching campaign leads."));
      }

      if (typeof resetCampaignLeadDetails === "function") { resetCampaignLeadDetails(); }
    }
  }

  _fetchCampaignLeadDetails = () => {
    const { fetchCampaignLeadDetails, /*APIRequestDateFormat*/ } = this.props;
    const { campaignId, leadsFilter } = this.state;

    const _leadsFilter = { ...leadsFilter };

    if (typeof fetchCampaignLeadDetails === "function") {

      // Update column value
      if (_leadsFilter.columnValues) {
        _leadsFilter.columnValues = _leadsFilter.columnValues.split("\n");
      }

      // Update Start date and end date format
      // if (_leadsFilter.startDate) {
      //   _leadsFilter.startDate = moment(_leadsFilter.startDate).format(APIRequestDateFormat);
      // }

      // if (_leadsFilter.endDate) {
      //   _leadsFilter.endDate = moment(_leadsFilter.endDate).format(APIRequestDateFormat);
      // }

      // Convert from camelCase to underscore
      const updatedLeadsFilter = {
        campaign_id: campaignId,
        match_key: (_leadsFilter.matchKey || ""),
        approval_ids: (_leadsFilter.approval || []),
        column_id: (_leadsFilter.columnId || null),
        column_value: (_leadsFilter.columnValues || []),
        asset_ids: (_leadsFilter.assetIds || []),
        upload_ids: (_leadsFilter.uploadIds || []),
        orderby: (_leadsFilter.sortColumn || "lead_id"),
        order: (_leadsFilter.sortOrder || "ASC"),
        piped: (_leadsFilter.piped !== null) ? _leadsFilter.piped : null,
        page_no: (_leadsFilter.pageNo || 1),
        page_size: (_leadsFilter.pageSize || 10)
      };

      fetchCampaignLeadDetails(updatedLeadsFilter);
    }
  }

  _setLeadListData = () => {
    const { leads } = this.props;

    this.setState({
      showLeadsTableLoader: false,
      reloadTable: false,
      leadsList: _get(leads, "data.lead_items", []),
      paginationInfo: _get(leads, "data.leads_page_info[0]", {}),
      campaignDetails: _get(leads, "data.campaign_name[0]", {}),
      CRMList: _get(leads, "data.crm_list", []),
      knobData: _get(leads, "data.knob_data[0]", {}),
    });
  }

  _setChangeLeadStatusStates = (status, rejectionReason = "", reasonType = null, submitRejectModal = false) => {
    const { intl } = this.props;
    const { selectedLeadIds } = this.state;

    if ((selectedLeadIds || []).length === 0) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
      }));

      return false;
    }

    this.setState({
      changeStatusState: {
        currentLeadsStatus: status,
        rejectionReason: rejectionReason,
        rejectionReasonType: reasonType,
        showStatusChangeModal: ([0, 1].includes(status) || (submitRejectModal === true)) ? true : false,
        showLeadRejectReasonModal: ([2].includes(status) || (submitRejectModal === true)) ? true : false
      }
    });
  }

  _handleChangeLeadStatus = async () => {
    const { updateLoadingState, intl } = this.props;
    const { selectedLeadIds, changeStatusState } = this.state;

    if ((selectedLeadIds || []).length === 0) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
      }));

      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await updateLeadStatus({
        "lead_ids": (selectedLeadIds || []),
        "status": _get(changeStatusState, "currentLeadsStatus", null),
        "rejection_reason": _get(changeStatusState, "rejectionReason", ""),
        "rejection_type": _get(changeStatusState, "rejectionReasonType", null)
      });

      if ((response.flag || false) === true) {
        showAlertMessage(_get(response, "message", "Lead status updated successfully."), "success");

        this.setState({ changeStatusState: { ...this.defaultChangeStatusState }, selectedLeadIds: [], selectAllLeads: false });

        // Refresh table list
        this._fetchCampaignLeadDetails();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while updating lead status."));

        this.setState({ changeStatusState: { ...this.defaultChangeStatusState } });
      }

    } catch (err) {
      this.setState({ changeStatusState: { ...this.defaultChangeStatusState } });
      showAlertMessage(err.message || "Something went wrong while updating lead status.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleExportLeads = async () => {
    const { updateLoadingState, intl } = this.props;
    const { campaignId, leadsFilter, selectAllLeads, selectedLeadIds } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const _leadsFilter = { ...leadsFilter };
      let leadIds = [];

      if ((selectedLeadIds || []).length === 0 && selectAllLeads === false) {
        leadIds = [];
      }

      if ((selectedLeadIds || []).length > 0 && selectAllLeads === false) {
        leadIds = selectedLeadIds;
      }

      // Update column value
      if ((_leadsFilter.columnValues || "") !== "") {
        _leadsFilter.columnValues = _leadsFilter.columnValues.split("\n");
      }

      const updatedLeadsFilter = {
        campaign_id: campaignId,
        lead_ids: leadIds,
        match_key: (_leadsFilter.matchKey || ""),
        approval_ids: (_leadsFilter.approval || []),
        column_id: (_leadsFilter.columnId || null),
        column_value: (_leadsFilter.columnValues || []),
        asset_ids: (_leadsFilter.assetIds || []),
        upload_ids: (_leadsFilter.uploadIds || []),
        piped: (_leadsFilter.piped !== null) ? _leadsFilter.piped : null,
      };

      const response = await exportSelectedLeads(updatedLeadsFilter);

      if (_get(response, "flag", false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.Contents || "");
        const filename = (downloadFileDetails.filename || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadExcelFile(contentType, contents, filename);

          showAlertMessage(intl.formatMessage({ id: "message.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while exporting lead(s)");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while exporting lead(s)");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _handelSearchText = ({ currentTarget: { value } }) => {
    this.setState(prevState => ({
      leadsFilter: {
        ...prevState.leadsFilter,
        pageNo: 1,
        matchKey: value
      }
    }));
  }

  _handleResetFilter = () => {
    const { disableApprovalFilter = false, leadsFilter: { approval = [] } } = this.state;

    const tmpDefaultLeadsFilter = _cloneDeep(defaultLeadsFilter);
    const tmpLeadFilter = (disableApprovalFilter === true) ? { ...tmpDefaultLeadsFilter, approval: approval } : tmpDefaultLeadsFilter;

    this.setState({
      selectedLeadIds: [],
      selectAllLeads: false,
      leadsFilter: tmpLeadFilter,
      filterAccordian: { ...this.defaultFilterAccordion },
      filterSelectedColumnId: null
    });
  }

  _handleAccordian = (key, value) => {

    this.setState(prevState => ({
      filterAccordian: {
        ...prevState.filterAccordian,
        [key]: value
      }
    }));
  }

  _closeUpdateLeadColumnPopover = (reloadTable) => {
    if (reloadTable) { this.setState({ reloadTable: true }); }

    document.body.click();
  }

  _updateFilterState = (args) => {
    const { stateLeadsFilter, showLeadFilterModal } = args;

    this.setState((prevState) => ({
      reloadTable: true,
      leadsFilter: { ...prevState.leadsFilter, ...stateLeadsFilter },
      showLeadFilterModal: showLeadFilterModal
    }));
  }

  _renderQAStatus = (row, path, isDelivery = 0) => {
    const qaStatus = _get(row, path, 0);

    let _html = "";

    // if ([0, 1, 3].includes(qaStatus)) {
    if (qaStatus === 1) {
      _html = (
        <span className={`badge ${LeadStatusWithAttributes.labelClass[qaStatus] || ""}`}>
          {(LeadStatusWithAttributes.qaLabels[qaStatus] || "")}
        </span>
      )
    } else {
      let reason = "";
      if (isDelivery === 1) {
        reason = _get(row, "delivery_fail", "");
      } else {
        reason = _get(row, "criteria_fail", "");
      }
      _html = (
        <div className={`${LeadStatusWithAttributes.labelFontClass[qaStatus] || ""} w-100`}>
          <span className="text-truncate d-block" title={reason}>{reason}</span>
        </div>
      )
    }

    return _html;
  }

  _renderApprovedStatus = (row, path) => {
    let _html = "";

    const status = _get(row, path, 0);

    const labelClass = (LeadStatusWithAttributes.labelClass[status] || "");
    const labelFontClass = (LeadStatusWithAttributes.labelFontClass[status] || "");
    const labelText = (LeadStatusWithAttributes.approveLabels[status] || "");

    if (status === 1) {
      _html = !_isEmpty(labelText) ? (
        <span className={`badge ${labelClass}`}>{labelText}</span>
      ) : "";
    } else {
      const reason = _get(row, "approval_fail", "");
      _html = !_isEmpty(reason) ? (
        <span className={`badge ${labelClass || ""} ${labelFontClass || ""} text-truncate d-block`} title={reason}>{reason}</span>
      ) : "";
    }

    return _html;
  }

  _renderTopFilters = () => {
    const { leadsFilter, disableApprovalFilter, filterAccordian, filterSelectedColumnId, showLeadFilterModal, campaignId, selectedLeadIds } = this.state;
    const { CRMList, selectAllLeads } = this.state;

    return (
      <>
        <div className="row gy-5">
          {(hasAccess("campaigns", "view-leads", ["export"])) && (
            <div className="col-lg-2">
              <label className="form-label text-capitalize d-block">
                <FormattedMessage id="view_leads.filter.export" defaultMessage="export" />
              </label>
              <button className="btn btn-outline-primary waves-effect w-100" onClick={() => this._handleExportLeads()}>
                <FormattedMessage id="btn.export" defaultMessage="export" />
              </button>
            </div>
          )}

          {(hasAccess("campaigns", "view-leads", ["update"])) && (
            <UpdateLeadColumn
              campaignId={campaignId}
              setState={(args) => this.setState(args)}
              selectedLeadIds={selectedLeadIds}
              onClose={(reloadTable = false) => this._closeUpdateLeadColumnPopover(reloadTable)}
            />
          )}

          {(hasAccess("campaigns", "view-leads", ["filter"])) && (
            <LeadFilters
              key={`show-filter-modal-${showLeadFilterModal}`}
              campaignId={campaignId}
              leadsFilter={{ ...leadsFilter }}
              accordianFilter={(filterAccordian || {})}
              showLeadFilterModal={showLeadFilterModal}
              disableApprovalFilter={(disableApprovalFilter || false)}
              filterSelectedColumnId={(filterSelectedColumnId || null)}
              setState={(args) => this.setState(args)}
              onUpdateLeadFilter={(args) => this._updateFilterState(args)}
              onClickAccordian={(key, value) => this._handleAccordian(key, value)}
            />
          )}

          {(hasAccess("campaigns", "view-leads", ["pipe-to-crm"])) && (
            <PipeToCRM
              leadsFilter={leadsFilter}
              campaignId={campaignId}
              selectedLeads={selectedLeadIds}
              crmList={(CRMList || [])}
              selectAllLeads={(selectAllLeads || false)}
              refreshLeadTable={(refreshTable) => this.setState({ reloadTable: refreshTable })}
            />
          )}

          {(hasAccess("campaigns", "view-leads", ["search"])) && (
            <div className="col-lg-3">
              <div>
                <label className="form-label text-capitalize"><FormattedMessage id="view_leads.filter.search" defaultMessage="search" /></label>
                <div className="input-group w-auto">
                  <input type="text" className="form-control" placeholder="Search ..." value={(leadsFilter.matchKey || "")} onChange={(e) => this._handelSearchText(e)} />
                  <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                    <img src={require("../../assets/icons/icon-search-sm.svg").default} className="img-fluid" alt="img" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row pt-5">
          {(hasAccess("campaigns", "view-leads", ["campaign-dashboard"])) && (
            <div className="col-lg-3">
              <div className="input-group">
                <span className="input-group-text bg-primary-66 border-primary-66 px-3 d-flex align-items-center">
                  <img src={require("../../assets/icons/icon-dashboard-alt.svg").default} className="img-fluid" alt="img" />
                </span>
                <Link to={`/campaigns/dashboard/${campaignId}`} className="btn btn-primary waves-effect">
                  <FormattedMessage id="campaign_content.btn.campaign_dashboard" defaultMessage="campaign dashboard" />
                </Link>
              </div>
            </div>
          )}

          <div className="col-lg-auto ms-auto">
            <button className="btn btn-dark waves-effect" onClick={() => this._handleResetFilter()}>
              <FormattedMessage id="btn.reset" defaultMessage="Reset" />
            </button>
          </div>
        </div>
      </>
    );
  }

  showSelectedRows = (row) => {
    const { selectedLeadIds } = this.state;

    return _includes(selectedLeadIds, row.lead_id);
  }

  render() {
    const { intl } = this.props;
    const { showLeadsTableLoader, leadsList, campaignDetails, knobData, pendingLeadsView, approvedLeadView, rejectedLeadsView, changeStatusState: { showStatusChangeModal, showLeadRejectReasonModal } } = this.state;

    return (
      <>
        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center gap-5 py-3 px-4">
                    <p className="heading-04 text-uppercase">{_get(campaignDetails, "campaign", <EmptyLabel />)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="view_leads.title" defaultMessage="view leads" />
              </h1>
            </div>
          </div>
          {((hasAccess("campaigns", "view-leads", ["page-view"])) ||
            (this.hasPendingLeadsAccess && pendingLeadsView) ||
            (this.hasApproveLeadsAccess && approvedLeadView) ||
            (this.hasRejectedLeadsAccess && rejectedLeadsView)
          ) && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="d-flex flex-wrap gap-4 align-items-center mb-5">
                      {(hasAccess("campaigns", "view-leads", ["mark-as-approved"])) && (
                        <button className="btn btn-outline-success ps-3 waves-effect" onClick={() => this._setChangeLeadStatusStates(1)}>
                          <img src={require("../../assets/icons/icon-done.svg").default} alt="info_icon" className="img-fluid me-4" />
                          <FormattedMessage id="view_leads.btn.mark_as_approved" defaultMessage="mark as approved" />
                        </button>
                      )}
                      {(hasAccess("campaigns", "view-leads", ["mark-as-rejected"])) && (
                        <button className="btn btn-outline-danger ps-3 waves-effect" onClick={() => this._setChangeLeadStatusStates(2)}>
                          <img src={require("../../assets/icons/icon-close.svg").default} alt="info_icon" className="img-fluid me-4" />
                          <FormattedMessage id="view_leads.btn.mark_as_rejected" defaultMessage="mark as rejected" />
                        </button>
                      )}
                      {(hasAccess("campaigns", "view-leads", ["mark-as-pending"])) && (
                        <button className="btn btn-outline-warning ps-3 waves-effect" onClick={() => this._setChangeLeadStatusStates(0)}>
                          <img src={require("../../assets/icons/icon-loading.svg").default} alt="info_icon" className="img-fluid me-4" />
                          <FormattedMessage id="view_leads.btn.mark_as_pending" defaultMessage="mark as pending" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {this._renderTopFilters()}

                <div className="row mt-5">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body py-5">
                        <div className="table-responsive custom-scroll">
                          <ReactDataTable
                            className="custom-scroll-460 leadsTable"
                            columns={this.tableColumns}
                            data={(leadsList || [])}
                            selectableRows
                            selectableRowSelected={this.showSelectedRows}
                            progressPending={showLeadsTableLoader} showLeadsTableLoader
                            progressComponent={<ShowContentLoader />}
                            conditionalRowStyles={[{
                              when: row => (_get(row, "piped", 0) === 1),
                              classNames: ['row-background-color'],
                            }]}
                            onSelectedRowsChange={(selectedRows) => {
                              this.setState({ selectedLeadIds: _map(_get(selectedRows, "selectedRows", []), "lead_id"), selectAllLeads: (selectedRows.allSelected || false) });
                            }}
                          />
                        </div>
                        <div className="mt-4">
                          <span className="text-capitalize-first fw-semibold text-grey-8e d-inline-block me-4">
                            <FormattedMessage
                              id="view_leads.knob.total_uploaded"
                              values={{ total: (knobData.crm_uploaded || 0) }}
                              defaultMessage="{total} Records Uploaded to Client CRM"
                            />
                          </span>
                          <span className="text-capitalize-first fw-semibold text-grey-8e d-inline-block me-4">
                            <FormattedMessage
                              id="view_leads.knob.total_awaiting"
                              values={{ total: (knobData.crm_awaiting || 0) }}
                              defaultMessage="{total} Records Awaiting Upload to Client CRM"
                            />
                          </span>
                          <span className="text-capitalize-first fw-semibold text-grey-8e d-inline-block me-4">
                            <FormattedMessage
                              id="view_leads.knob.total_pending"
                              values={{ total: (knobData.crm_pending || 0) }}
                              defaultMessage="{total} Records Pending Upload to Client CRM"
                            />
                          </span>
                          <span className="text-capitalize-first fw-semibold text-grey-8e d-inline-block">
                            <FormattedMessage
                              id="view_leads.knob.failed"
                              values={{ total: (knobData.crm_failed || 0) }}
                              defaultMessage="{total} Records Failed During Upload to Client CRM"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>

        <RejectLeadModal
          key={(showLeadRejectReasonModal || false) ? "show" : "hide"}
          show={(showLeadRejectReasonModal || false)}
          onSubmit={(status, reason, reasonType) => this._setChangeLeadStatusStates(status, reason, reasonType, true)}
          onHide={() => this.setState({ changeStatusState: { ...this.defaultChangeStatusState } })}
        />

        <ConfirmBox
          isOpen={(showStatusChangeModal || false)}
          onConfirm={() => this._handleChangeLeadStatus()}
          onClose={() => this.setState({ changeStatusState: { ...this.defaultChangeStatusState } })}
          content={intl.formatMessage({ id: "confirm.change_lead_status", defaultMessage: "Are you sure want to change the selected lead(s) status?" })}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  leads: _get(state, "campaign.leads", {}),
  APIRequestDateFormat: _get(state, "application.constants.APIRequestDateFormat", "YYYY-MM-DD"),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  fetchCampaignLeadDetails: (data) => dispatch(fetchCampaignLeadDetails(data)),
  resetCampaignLeadDetails: () => dispatch(resetCampaignLeadDetails()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ViewLeads)));
