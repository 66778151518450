import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, max as _max, cloneDeep as _cloneDeep } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MapDataWorld from "@highcharts/map-collection/custom/world.geo.json";
import Helmet from "react-helmet";
import moment from "moment";

import { showAlertMessage, HighChart, EmptyLabel, Switch, ShowContentLoader } from "../../resusableComponents/common/formElements";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { withRouter } from "../../resusableComponents/hoc/withRouter";
import MarkAsButton from "../../resusableComponents/common/MarkAsButton";

import { fetchCampaignDashboardData, clearCampaignDashboardError } from "../../redux/actions/campaign";
import { fetchJobFunctionData } from "../../redux/services/campaign";

class CampaignDashboard extends Component {

  constructor(props) {
    super(props);

    this._mapRef = null;

    this.trackerChartOptions = { title: { text: null }, series: [], xAxis: { categories: [] }, yAxis: { categories: [] }, credits: { enabled: false } };
    this.mapDefaultOptions = {
      chart: { height: 290, map: "custom/world" }, tooltip: {
        headerFormat: "<span style=\"font-size: 10px\">{point.region}</span><br/>",
        "pointFormat": "<b>{point.region}</b>: {point.total}"
      }, title: { text: null }, subtitle: { text: null }, exporting: { enabled: true }, colors: ["#2caffe", "#fe6a35", "#d568fb", "#2ee0ca", "#fa4b42", "#6b8abc", "#544fc5"], mapNavigation: { enabled: true, enableDoubleClickZoomTo: true }, legend: { title: { text: "", style: { color: "black" } }, align: "left", verticalAlign: "bottom", floating: true, layout: "vertical", valueDecimals: 0, backgroundColor: "rgba(255, 255, 255, 0.85)", symbolRadius: 0, symbolHeight: 14 }, colorAxis: { dataClasses: [{ to: 3, color: "#2caffe" }, { from: 3, to: 10, color: "#fe6a35" }, { from: 10, to: 30, color: "#d568fb" }, { from: 30, to: 100, color: "#2ee0ca" }, { from: 100, to: 300, color: "#fa4b42" }, { from: 300, to: 1000, color: "#6b8abc" }, { from: 1000, color: "#544fc5" }] }, series: [{ data: [], joinBy: ["iso-a2", "iso_2"], name: "Region", allAreas: true, states: { hover: { color: "#a4edba", borderColor: "gray" } }, dataLabels: { enabled: false, format: "{point.region}" } }], credits: { enabled: false }
    };
    this.jobFunctionChartOptions = { title: { text: null }, chart: { type: "bar", marginRight: 50, marginTop: 20, height: 290 }, plotOptions: { bar: { dataLabels: { enabled: false } }, series: {} }, colors: ["#5C2056", "#6B2363", "#792972", "#9A338F", "#A24699", "#AB57A2", "#B66BAE", "#BD7BB8", "#C690C2", "#DAB5D6"], xAxis: { categories: [] }, yAxis: {}, options: { responsive: true }, legend: { enabled: false }, series: [{ color: { linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, "#66b2b2"], [1, "#0de8f4"]] }, data: [] }], credits: { enabled: false } };
    this.industryChartOptions = { title: { text: null }, chart: { type: "bar", marginRight: 50, height: 290 }, plotOptions: { bar: { dataLabels: { enabled: false } }, series: { dataLabels: { align: "left", color: "#FFFFFF", enabled: true, x: 0 } } }, colors: ["#66b2b2", "#6b2363", "#792971", "#99338f", "#a14699", "#ac57a2", "#b56bad", "#bd7cb8", "#c690c2", "#dab5d5"], xAxis: { categories: [], }, yAxis: { min: 0, max: 30, showEmpty: false, tickInterval: 5, labels: { align: "right", } }, legend: { enabled: false }, series: [{ color: { linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, "#1bc582"], [1, "#4ce5aa"]] }, data: [] }], credits: { enabled: false } };
    this.assetsChartOptions = {
      title: { text: null }, chart: { type: "bar", marginRight: 50, height: 290 }, tooltip: {
        formatter: function () {
          return `<b>${this.point.category}</b>: ${(this.point.y || 0)}`;
        }
      },
      plotOptions: { bar: { dataLabels: { enabled: false } }, series: { dataLabels: { align: "left", color: "#FFFFFF", enabled: true, x: 0 } } }, colors: ["#66b2b2", "#6b2363", "#792971", "#99338f", "#a14699", "#ac57a2", "#b56bad", "#bd7cb8", "#c690c2", "#dab5d5"], xAxis: { categories: [], }, yAxis: { min: 0, max: 30, showEmpty: false, tickInterval: 5, labels: { align: "right", } }, legend: { enabled: false }, series: [{ color: { linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, "#1bc582"], [1, "#4ce5aa"]] }, data: [] }], credits: { enabled: false }
    };
    this.assetByRegionOptions = { chart: { type: "column", height: 290 }, title: { text: null }, xAxis: { categories: [], crosshair: true }, yAxis: { min: 0, title: { text: "Values" } }, plotOptions: { column: { pointPadding: 0.2, borderWidth: 0, dataLabels: { enabled: false } } }, series: [], credits: { enabled: false } };
    this.industryByRegionOptions = { chart: { type: "column", height: 290 }, title: { text: null }, xAxis: { categories: [], crosshair: true }, yAxis: { min: 0, title: { text: "Values" } }, plotOptions: { column: { pointPadding: 0.2, borderWidth: 0, dataLabels: { enabled: false } } }, series: [], credits: { enabled: false } };
    this.regionByJobTitleOptions = { chart: { type: "bar", height: 290 }, title: { text: null }, xAxis: { categories: [], crosshair: true }, yAxis: { min: 0, opposite: true, enabled: true, title: { text: "Values" } }, plotOptions: { column: { pointPadding: 0.2, borderWidth: 0, dataLabels: { enabled: false } } }, series: [], credits: { enabled: false }, legend: { verticalAlign: 'top', } };

    this.state = {
      campaignDetails: {},
      tilesData: {},
      campaignId: _get(this.props, "params.id", 0),
      trackerChartOptions: { ...this.trackerChartOptions },
      reloadCampaignDashboard: false,
      jobFunctionChartLoader: false,
      jobFunctionChartData: [],
      jobFunctionChartOptions: { ...this.jobFunctionChartOptions },
      industryChartLoader: true,
      industryChartOptions: { ...this.industryChartOptions },
      industryChartToggle: "percentage",
      jobFunctionChartToggle: "percentage",
      assetsChartLoader: true,
      assetsChartOptions: { ...this.assetsChartOptions },
      locationMapOption: { ...this.mapDefaultOptions },
      assetByRegionOptions: { ...this.assetByRegionOptions },
      industryByRegionOptions: { ...this.industryByRegionOptions },
      regionByJobTitleOptions: { ...this.regionByJobTitleOptions },
      jobLevels: []
    }
  }

  componentDidMount() {
    const { fetchCampaignDashboardData, dashboardData } = this.props;
    const { campaignId } = this.state;

    if (typeof fetchCampaignDashboardData === "function") {
      fetchCampaignDashboardData(campaignId || 0);
    }
    this._setDashboardData();
    this._setIndustryChartData();
    this._setJobFunctionData(_get(dashboardData, "data.job_function", []));
    this._setAssetsChartData();
    this._setLocationRegionData();
    this.setAssetsByRegionChartData();
    this.setIndustryByRegionChartData();
    this.setRegionByJobTitleOptions();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.dashboardData, this.props.dashboardData) && !_isEmpty(this.props.dashboardData)) {
      const { dashboardData, clearCampaignDashboardError } = this.props;
      const responseFlag = _get(dashboardData, "flag", null);

      if (responseFlag === null) { return false; }

      if (responseFlag === true) {
        showAlertMessage(_get(dashboardData, "message", "Campaign dashboard data fetched successfully."), "success");

        this._setDashboardData();
        this._setIndustryChartData();
        this._setJobFunctionData(_get(this.props, "dashboardData.data.job_function", []));
        this._setAssetsChartData();
        this._setLocationRegionData();
        this.setAssetsByRegionChartData();
        this.setIndustryByRegionChartData();
        this.setRegionByJobTitleOptions();
      } else if (responseFlag === false) {
        showAlertMessage(_get(dashboardData, "message", "Something went wrong while fetching campaign dashboard data."));

        if (typeof clearCampaignDashboardError === "function") { clearCampaignDashboardError(); }
      }
    }

    if (!_isEqual(prevState.reloadCampaignDashboard, this.state.reloadCampaignDashboard) && (_get(this.state, "reloadCampaignDashboard", false) === true)) {
      const { fetchCampaignDashboardData } = this.props;
      const { campaignId } = this.state;

      if (typeof fetchCampaignDashboardData === "function") {
        fetchCampaignDashboardData(campaignId || 0);
      }
      this.setState({ reloadCampaignDashboard: false });
    }

    if (!_isEqual(_get(prevState, "jobFunctionChartToggle", "percentage"), _get(this.state, "jobFunctionChartToggle", "percentage"))) {
      this._setJobFunctionData(_get(this.state, "jobFunctionChartData", []));
    }
  }

  _setDashboardData = () => {
    const { dashboardData, campaignTrackerDisplayDateFormat } = this.props;
    const { trackerChartOptions } = this.state;

    const trackerOptions = {
      ...trackerChartOptions,
      series: [
        {
          name: "Target Leads",
          color: "#5b49b9",
          data: _get(dashboardData, "data.campaign_tracker.target_leads", [])
        },
        {
          name: "Approved Leads",
          color: "#66b2b2",
          data: _get(dashboardData, "data.campaign_tracker.approve_leads", [])
        }
      ],
      xAxis: {
        categories: _get(dashboardData, "data.campaign_tracker.xAxis.categories", []),
        labels: {
          align: "right",
          formatter: function () {
            return moment(this.value).format(campaignTrackerDisplayDateFormat);
          }
        }
      },
      yAxis: { min: 0, showEmpty: false, tickInterval: 50 },
    };

    this.setState({
      campaignDetails: _get(dashboardData, "data.campaign_details[0]", {}),
      tilesData: _get(dashboardData, "data.campaign_numbers[0]", {}),
      trackerChartOptions: trackerOptions
    });
  };

  _setJobFunctionData = (chartData) => {
    const { jobFunctionChartToggle/*, jobLevels*/ } = this.state;

    this.setState({ jobFunctionLoader: true, jobFunctionChartData: (chartData || []) });

    let xAxisData = [];
    let seriesData = [];

    (chartData || []).forEach((a) => {

      const data = ((jobFunctionChartToggle === "percentage") ? (a.percent || 0) : (a.total || 0));
      seriesData.push(data);

      xAxisData.push((a.job_function || "N/A"));
    });

    const maxYAxis = ((jobFunctionChartToggle === "percentage") ? { max: 100 } : { max: ((_max(seriesData) || 0)) });

    this.setState({
      jobFunctionOption: {
        ...this.jobFunctionChartOptions,
        xAxis: {
          categories: (xAxisData || [])
        },
        yAxis: {
          min: 0,
          ...maxYAxis,
          showEmpty: false,
          labels: {
            align: "right",
            formatter: function () {
              return (((jobFunctionChartToggle || "percentage") === "percentage") ? (`${(this.value || 0)} %`) : (this.value || 0));
            }
          }
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.point.category}</b>: ${((jobFunctionChartToggle === "percentage") ? (`${(this.point.y || 0)} %`) : (this.point.y || 0))}`;
          },
        },
        series: [{
          data: seriesData,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, "#66b2b2"], [1, "#66b2b2"]]
          }
        }],
        /*plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  const department = _get(e, "point.category", "");

                  // Level up Job level
                  if ((jobLevels || []).length < 1) {
                    this.setState((prevState) => ({
                      jobLevels: [...(prevState.jobLevels || []), (department || "")].filter(j => j)
                    }), () => {
                      this._filterJobFunction();
                    });
                  }
                }
              }
            }
          }
        }*/
      },
      jobFunctionLoader: false
    });
  };

  _handleJobFunctionBack = () => {
    const { jobLevels } = this.state;

    let tmpJobLevels = [...jobLevels];

    tmpJobLevels.splice(-1, 1);

    this.setState({ jobLevels: tmpJobLevels }, () => {
      this._filterJobFunction();
    });
  };

  _filterJobFunction = async () => {
    const { campaignId, jobLevels } = this.state;

    let jobFunctionData = [];

    this.setState({ jobFunctionLoader: true });

    try {

      const result = await fetchJobFunctionData({
        campaign_id: campaignId,
        department: _get(jobLevels, "[0]", null),
        job_function1: _get(jobLevels, "[1]", null),
      });

      if ((result.flag || false) === true) {
        jobFunctionData = (_get(result, "data.job_function", []) || []).map(j => ({
          job_function: (j.job_function || ""),
          total: (j.job_function_count || 0),
          percent: (j.percent || 0),
        }));
      } else {
        showAlertMessage("Something went wrong while fetching job functions data.");
      }
    } catch (error) {
      showAlertMessage("Something went wrong while fetching job functions data.");
    }

    this._setJobFunctionData(jobFunctionData);
  };

  _setIndustryChartData = (checked = "percentage") => {
    const { dashboardData } = this.props;
    const { industryChartOptions } = this.state;

    const seriesData = ((checked || "values") === "values") ? _get(dashboardData, "data.industries.values", []) : _get(dashboardData, "data.industries.percentage", []);
    const maxYAxis = (((checked || "values") === "percentage") ? { max: ((_max(seriesData) || 100)) } : { max: ((_max(seriesData) || 0)) });

    const industryOptions = {
      ...industryChartOptions,
      xAxis: { categories: _get(dashboardData, "data.industries.categories", []) },
      yAxis: {
        min: 0,
        ...maxYAxis,
        showEmpty: false,
        tickInterval: ((checked || "values") === "percentage") ? 5 : 10,
        labels: {
          align: "right",
          formatter: function () {
            return (((checked || "values") === "percentage") ? (`${(this.value || 0)} %`) : (this.value || 0));
          }
        }
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.point.category}</b>: ${(((checked || "values") === "percentage") ? (`${(this.point.y || 0)} %`) : (this.point.y || 0))}`;
        }
      },
      series: [{ data: seriesData }]
    };

    this.setState({ industryChartLoader: false, industryChartOptions: industryOptions, industryChartToggle: checked });
  };

  _setAssetsChartData = () => {
    const { dashboardData } = this.props;
    const { assetsChartOptions } = this.state;

    const seriesData = _get(dashboardData, "data.assets.values", []);
    const maxYAxis = (_max(seriesData) || 0);

    const assetsOptions = {
      ...assetsChartOptions,
      xAxis: { categories: _get(dashboardData, "data.assets.categories", []) },
      yAxis: {
        min: 0,
        ...maxYAxis,
        showEmpty: false,
      },
      series: [{ data: seriesData }]
    };

    this.setState({ assetsChartLoader: false, assetsChartOptions: assetsOptions });
  };

  setAssetsByRegionChartData = () => {
    const { dashboardData } = this.props;
    const { assetByRegionOptions } = this.state;

    const assetsByRegion = _get(dashboardData, "data.assets_by_region", []);

    const categories = [...new Set(assetsByRegion.map(item => item.region))];
    const assetTotals = {};

    assetsByRegion.forEach(({ asset_code, region, total }) => {
      if (!assetTotals[asset_code]) {
        assetTotals[asset_code] = new Array(categories.length).fill(0);
      }
      const regionIndex = categories.indexOf(region);
      assetTotals[asset_code][regionIndex] += total;
    });

    const sortedAssetCodes = Object.entries(assetTotals)
      .sort((a, b) => {
        const maxA = Math.max(...a[1]);
        const maxB = Math.max(...b[1]);
        return maxB - maxA;
      })
      .map(([name]) => name);

    const series = sortedAssetCodes.map(name => ({ name, data: assetTotals[name] }));

    const assetsRegionsOptions = {
      ...assetByRegionOptions,
      xAxis: { categories: categories, crosshair: true },
      series: series
    };

    this.setState({ assetsChartLoader: false, assetByRegionOptions: assetsRegionsOptions });
  };

  setIndustryByRegionChartData = () => {
    const { dashboardData } = this.props;
    const { industryByRegionOptions } = this.state;

    const industryByRegion = _get(dashboardData, "data.industry_by_region", []);

    const categories = [...new Set(industryByRegion.map(item => item.region))];
    const industryTotals = {};

    industryByRegion.forEach(({ industry, region, total }) => {
      if (!industryTotals[industry]) {
        industryTotals[industry] = new Array(categories.length).fill(0);
      }
      const regionIndex = categories.indexOf(region);
      industryTotals[industry][regionIndex] += total;
    });

    const sortedIndustryCodes = Object.entries(industryTotals)
      .sort((a, b) => {
        const maxA = Math.max(...a[1]);
        const maxB = Math.max(...b[1]);
        return maxB - maxA;
      })
      .map(([name]) => name);

    const series = sortedIndustryCodes.map(name => ({ name, data: industryTotals[name] }));

    const industryRegionOptions = {
      ...industryByRegionOptions,
      xAxis: { categories: categories, crosshair: true },
      series: series
    };

    this.setState({ assetsChartLoader: false, industryByRegionOptions: industryRegionOptions });
  };

  setRegionByJobTitleOptions = () => {
    const { dashboardData } = this.props;
    const { regionByJobTitleOptions } = this.state;

    const regionByJobTitle = _get(dashboardData, "data.job_title_by_region", []);

    const categories = [...new Set(regionByJobTitle.map(item => item.job_title))];
    const jobTitleTotals = {};

    regionByJobTitle.forEach(({ region, job_title, total }) => {
      if (!jobTitleTotals[region]) {
        jobTitleTotals[region] = new Array(categories.length).fill(0);
      }
      const JobTitleIndex = categories.indexOf(job_title);
      jobTitleTotals[region][JobTitleIndex] += total;
    });

    const sortedJobTitleCodes = Object.entries(jobTitleTotals)
      .sort((a, b) => {
        const maxA = Math.max(...a[1]);
        const maxB = Math.max(...b[1]);
        return maxB - maxA;
      })
      .map(([name]) => name);

    const series = sortedJobTitleCodes.map(name => ({ name, data: jobTitleTotals[name] }));
    var chartHeight = ((categories || []).length) > 20 ? ((categories || []).length) * 10 : 400;

    const jobTitleRegionOptions = {
      ...regionByJobTitleOptions,
      chart: { type: "bar", height: chartHeight },
      xAxis: {
        categories: categories, crosshair: true
      },
      series: series
    };

    this.setState({ assetsChartLoader: false, regionByJobTitleOptions: jobTitleRegionOptions });
  };

  _setLocationRegionData = () => {
    const { dashboardData } = this.props;

    const locationMapOption = {
      ...this.mapDefaultOptions,
      series: [{
        mapData: MapDataWorld,
        data: _cloneDeep(_get(dashboardData, "data.engaged_leads", [])),
        joinBy: ["iso-a2", "iso_2"],
        name: "Region",
        allAreas: true,
        states: { hover: { color: "#a4edba", borderColor: "gray" } },
        "colorKey": "total",
        dataLabels: { enabled: true, format: "{point.total}" }
      }]
    };

    this.setState({ locationMapOption });
  };

  _renderTiles = () => {
    const { tilesData, campaignId } = this.state;

    const hasApproveLeadLinkPermission = hasAccess("campaigns", "dashboard", ["approved-leads-link"]);
    const hasRejectedLeadLinkPermission = hasAccess("campaigns", "dashboard", ["rejected-leads-link"]);
    const hasPendingLeadLinkPermission = hasAccess("campaigns", "dashboard", ["pending-leads-link"]);

    return (
      <div className="row gy-4 mt-2">
        <div className="col-lg-3">
          <div className="card-static border-blue-55">
            <h6 className="text-blue-55">{_get(tilesData, "complete_percent", 0)}</h6>
            <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.completed" defaultMessage="completed" /></p>
          </div>
        </div>
        <div className="col-lg-3">
          <Link to={(hasApproveLeadLinkPermission) ? `/campaigns/approved-leads/${(campaignId || null)}` : "#"}>
            <div className="card-static border-success">
              <h6 className="text-success">{_get(tilesData, "approved_leads", 0)}</h6>
              <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.approved_leads" defaultMessage="approved leads" /></p>
            </div>
          </Link>
        </div>
        <div className="col-lg-3">
          <Link to={(hasRejectedLeadLinkPermission) ? `/campaigns/rejected-leads/${(campaignId || null)}` : "#"}>
            <div className="card-static border-danger">
              <h6 className="text-danger">{_get(tilesData, "rejected_leads", 0)}</h6>
              <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.rejected_leads" defaultMessage="rejected leads" /></p>
            </div>
          </Link>
        </div>
        <div className="col-lg-3">
          <Link to={(hasPendingLeadLinkPermission) ? `/campaigns/pending-leads/${(campaignId || null)}` : "#"}>
            <div className="card-static border-warning">
              <h6 className="text-warning">{_get(tilesData, "pending_leads", 0)}</h6>
              <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.pending_leads" defaultMessage="pending leads" /></p>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  _renderCampaignButtons = () => {
    const { campaignId } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="d-flex flex-wrap gap-3 align-items-center">
            {(hasAccess("campaigns", "dashboard", ["edit-campaign"])) && (
              <Link to={`/campaigns/edit/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.edit_campaign" defaultMessage="edit campaign" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["campaign-content"])) && (
              <Link to={`/campaigns/content/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.campaign_content" defaultMessage="campaign content" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["upload-leads"])) && (
              <Link to={`/campaigns/upload-lead/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.upload_leads" defaultMessage="upload leads" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["data-history"])) && (
              <Link to={`/campaigns/data-history/${campaignId}`} className="btn btn-outline-primary waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.data_history" defaultMessage="data history" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["view-leads"])) && (
              <Link to={`/campaigns/view-leads/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.view_leads" defaultMessage="view leads" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["mark-as-complete"])) && (
              <MarkAsButton
                className="btn btn-outline-primary waves-effect"
                buttonId="campaign_list.button.mark_as_complete"
                buttonDefultMessage="mark as complete"
                operationStatus="complete"
                selectedCampaignIds={[campaignId]}
                source="campaign_dashboard"
                setState={(args) => this.setState(args)}
              />
            )}

            {(hasAccess("campaigns", "dashboard", ["mark-as-live"])) && (
              <MarkAsButton
                className="btn btn-outline-primary waves-effect"
                buttonId="campaign_list.button.mark_as_live"
                buttonDefultMessage="mark as live"
                operationStatus="live"
                selectedCampaignIds={[campaignId]}
                source="campaign_dashboard"
                setState={(args) => this.setState(args)}
              />
            )}

          </div>
        </div>
      </div>
    );
  }

  _renderJobFunction = () => {
    const { jobFunctionChartToggle, jobLevels, jobFunctionLoader, jobFunctionOption } = this.state;

    return (
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center gap-2 justify-content-between mb-4">
              <h3 className="heading-04 fw-semibold text-capitalize">
                <FormattedMessage id="campaign_dashboard.job_function" defaultMessage="job function" />
              </h3>
              <div className="text-right">
                <Switch
                  offContent="%"
                  checked={((jobFunctionChartToggle === "percentage") ? true : false)}
                  onChange={(e) => {
                    this.setState({
                      jobFunctionChartToggle: ((_get(e, "currentTarget.checked", false) === false) ? "value" : "percentage")
                    });
                  }}
                />
              </div>
            </div>

            <div>
              {((jobLevels || []).length > 0) && (
                <span className="span-link jf-back-link text-capitalize cursor-pointer" onClick={() => this._handleJobFunctionBack()}>
                  <img src={require("../../assets/icons/icon-chev-left.svg").default} className="img-fluid" alt="img" />
                  <span className="ml-5"><FormattedMessage id="btn.back" defaultMessage="Back" /></span>
                </span>
              )}
              {((jobFunctionLoader || false) === false) ? (
                <HighChart options={(jobFunctionOption || {})} />
              ) : (
                <ShowContentLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderIndustry = () => {
    const { industryChartOptions, industryChartToggle, industryChartLoader } = this.state;

    return (
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center gap-2 justify-content-between mb-4">
              <h3 className="heading-04 fw-semibold text-capitalize">
                <FormattedMessage id="campaign_dashboard.industry" defaultMessage="industry" />
              </h3>
              <div className="text-right">
                <Switch
                  offContent="%"
                  checked={(((industryChartToggle || "percentage") === "percentage") ? true : false)}
                  onChange={(e) => {
                    const checked = (_get(e, "currentTarget.checked", false) === false) ? "values" : "percentage";

                    this._setIndustryChartData(checked);
                  }}
                />
              </div>
            </div>

            <div>
              {((industryChartLoader || false) === false) ? (
                <HighChart options={(industryChartOptions || {})} />
              ) : (
                <ShowContentLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderAssets = () => {
    const { assetsChartLoader, assetsChartOptions } = this.state;

    return (
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3 className="heading-04 mt-2 mb-4 fw-semibold text-capitalize">
              <FormattedMessage id="campaign_dashboard.assets" defaultMessage="assets" />
            </h3>
            <div>
              {((assetsChartLoader || false) === false) ? (
                <HighChart options={(assetsChartOptions || {})} />
              ) : (
                <ShowContentLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderEngagedLeads = () => {
    const { locationMapOption } = this.state;

    return (
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3 className="heading-04 mt-2 mb-4 fw-semibold text-capitalize">
              <FormattedMessage id="campaign_dashboard.engaged_leads" defaultMessage="engaged leads" />
            </h3>
            <div>
              <HighChart
                type="mapChart"
                options={_cloneDeep(locationMapOption || {})}
                getChartRef={(chart) => (this._mapRef = chart)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderAssetByRegion = () => {
    const { assetsChartLoader, assetByRegionOptions } = this.state;

    return (
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3 className="heading-04 mt-2 mb-4 fw-semibold text-capitalize">
              <FormattedMessage id="campaign_dashboard.assets_by_region" defaultMessage="assets by region" />
            </h3>
            <div>
              {((assetsChartLoader || false) === false) ? (
                <HighChart options={(assetByRegionOptions || {})} />
              ) : (
                <ShowContentLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderIndustryByRegion = () => {
    const { assetsChartLoader, industryByRegionOptions } = this.state;

    return (
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3 className="heading-04 mt-2 mb-4 fw-semibold text-capitalize">
              <FormattedMessage id="campaign_dashboard.industries_by_region" defaultMessage="industries by region" />
            </h3>
            <div>
              {((assetsChartLoader || false) === false) ? (
                <HighChart options={(industryByRegionOptions || {})} />
              ) : (
                <ShowContentLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderJobTitleByRegion = () => {
    const { assetsChartLoader, regionByJobTitleOptions } = this.state;

    return (
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <h3 className="heading-04 mt-2 mb-4 fw-semibold text-capitalize">
              <FormattedMessage id="campaign_dashboard.region_by_job_titles" defaultMessage="regions by job title" />
            </h3>
            <div className="chart-scroll">
              {((assetsChartLoader || false) === false) ? (
                <HighChart options={(regionByJobTitleOptions || {})} />
              ) : (
                <ShowContentLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { campaignDetails, trackerChartOptions } = this.state;

    const logoURL = (_get(campaignDetails, "client_website", "")) ? `https://logo.clearbit.com/${_get(campaignDetails, "client_website", "")}` : require("../../assets/images/no-img-icon.png").default;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "hermes.campaign_dashboard", defaultMessage: "Campaign Dashboard: Hermes" })} </title>
        </Helmet>

        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center gap-5 py-3 px-4">
                    <img className="img-fluid me-5" width="30" src={(logoURL || "")} alt="" />
                    <p className="heading-04 text-uppercase">
                      {_get(campaignDetails, "campaign_name", <EmptyLabel />)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this._renderTiles()}

          <div className="row mt-5">
            <div className="col-lg-12">
              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="campaign_dashboard.title" defaultMessage="dashboard" />
              </h1>
            </div>
          </div>

          {this._renderCampaignButtons()}

          {(hasAccess("campaigns", "dashboard", ["page-view"])) && (
            <>
              <div className="row mt-6">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="heading-04 mt-2 mb-4 fw-semibold text-capitalize">
                        <FormattedMessage id="campaign_dashboard.campaign_tracker" defaultMessage="campaign tracker" />
                      </h3>
                      <div>
                        <HighChart options={(trackerChartOptions || {})} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-6">
                {this._renderJobFunction()}
                {this._renderIndustry()}
              </div>

              <div className="row mt-6">
                {this._renderAssets()}
                {this._renderEngagedLeads()}
              </div>

              <div className="row mt-6">
                {this._renderAssetByRegion()}
                {this._renderIndustryByRegion()}
              </div>
              <div className="row mt-6">
                {this._renderJobTitleByRegion()}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  dashboardData: _get(state, "campaign.dashboard", {}),
  campaignTrackerDisplayDateFormat: _get(state, "application.constants.campaignTrackerDisplayDateFormat", "DD MMM YY"),
});

const mapDispatchToProps = (dispatch) => ({
  clearCampaignDashboardError: () => dispatch(clearCampaignDashboardError()),
  fetchCampaignDashboardData: (data) => dispatch(fetchCampaignDashboardData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignDashboard)));
