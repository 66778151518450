import React, { Component } from "react";
import PropTypes from "prop-types";

class Switch extends Component {

  static defaultProps = {
    onChange: () => { },
    checked: false,
    offContent: "",
    onContent: ""
  };

  _handleChange = (e) => {
    const { onChange } = this.props;

    if (typeof onChange === "function") {
      onChange(e);
    }
  }

  render() {
    const { checked, onContent, offContent } = this.props;

    return (
      <label className="switch">
        <input
          type="checkbox"
          checked={(checked || false)}
          onChange={(e) => this._handleChange(e)}
        />
        <span
          className="slider round"
          data-before={(onContent || "")}
          data-after={(offContent || "")}
        />
      </label>
    )
  }
}

Switch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  onContent: PropTypes.string,
  offContent: PropTypes.string
};

export default Switch;
