import { get as _get } from "lodash";
import api from "../../helpers/api";

export const fetchCampaignDashboardData = (campaignId) => {
  return api().get(`/campaign/dashboard/${campaignId}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching campaign dashboard data."),
      data: {},
    };
  });
};

export const getCampaignAssetList = (variables) => {
  return api().options("/campaign/assets", { data: (variables || {}) }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Campaign content data fetched successfully."),
      data: _get(res, "data.responseData", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching campaign content data."),
      data: {},
    };
  });
};

export const doGetCampaignList = (variables) => {
  return api().options("/campaign/campaign_list", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Campaign list fetched successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching campaign list."),
      data: {},
    };
  });
};

export const deleteCampaignAssets = (campaignId, data) => {
  return api().delete(`/campaign/delete_assets/${campaignId}`, { data }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Campaign Asset deleted successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while deleting campaign asset."),
      data: {}
    };
  });
}

export const doDeleteCampaign = (campaignIds) => {
  return api().delete("/campaign/bulk", { data: (campaignIds || []) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Campaign deleted successfully."),
      data: _get(res, "data.responseData", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while deleting campaign."),
      data: {},
    };
  });
};

export const doMarkAsCompleteCampaign = (variables) => {
  return api().patch("/campaign/mark_completed", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Campaign marked as completed successfully."),
      data: _get(res, "data.responseData", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while marking campaign as completed."),
      data: {},
    };
  });
};

export const exportAllCampaignAssets = (variables) => {
  return api().options("/campaign/list/export/all", { data: (variables) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while exporting campaigns."),
      data: {},
    };
  });
};

export const exportSelectedCampaignAssets = (variables) => {
  return api().options(`/campaign/assets/export`, { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Assets exported successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while exporting asset(s)."),
      data: {}
    };
  });
}

export const downloadCampaignAssetsAsZip = (variables) => {
  return api().options(`/campaign/assets/downloads`, { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Assets downloaded successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while downloading asset(s)."),
      data: {}
    };
  });
}

export const fetchCampaignDetails = (campaignId) => {
  return api().get(`/campaign/detail/${(campaignId || 0)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching campaign details."),
      data: {},
    };
  });
};

export const exportCampaignBriefs = (variables) => {
  return api().options(`/campaign/export/briefs`, { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Briefs exported successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while exporting Briefs."),
      data: {}
    };
  });
}

export const cloneSelectedBrief = (variables) => {

  return api().post(`/campaign/brief/${variables.brief_id}/clone`, {}).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while copying brief."),
      error: _get(err, "response.data.responseData.errors", {}),
    };
  });
}

export const exportUploadTemplateData = (variables) => {
  return api().options(`/campaign/export/lead_upload_template`, { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "File generated successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while exporting upload template."),
      data: {}
    };
  });
}

export const sendExportUploadTemplateMail = (variables) => {
  return api().options(`/campaign/export/lead_upload_template`, { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Email sent successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while sending email."),
      data: {}
    };
  });
}

export const createAssetsWithExisting = (variables) => {

  return api().post("/campaign/create_asset_with_existng", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while storing uploaded data."),
      error: _get(err, "response.data.responseData.errors", {}),
      data: {}
    };
  });
}

export const fetchCampaignLeadDetails = (variables) => {
  return api().options("lead/campaign", { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong fetching campaign leads."),
      data: {}
    };
  });
}

export const updateLeadStatus = (variables) => {
  return api().patch("/lead/approval_status", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Lead status updated successfully."),
      data: _get(res, "data.responseData", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while updating lead status."),
      data: {},
    };
  });
};

export const exportSelectedLeads = (variables) => {
  return api().options("/lead/export", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "File exported successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while exporting lead(s)."),
      data: {}
    };
  });
}

export const fetchCampaignLeadFilter = (campaignId) => {
  return api().get(`lead/${(campaignId || 0)}/combined/details`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching filter values."),
      data: {},
    };
  });
};

export const fetchPipingLeadCounts = (variables) => {
  return api().options("/lead/lead_count_piping", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Leads count fetched successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching leads count."),
      data: {}
    };
  });
}

export const submitLeadsToCRM = (variables) => {

  return api().post("lead/initiate_piping", variables).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Leads successfully piped to partner's CRM."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while pushing leads to CRM."),
      data: {}
    };
  });
}

export const getLeadColumnList = () => {
  return api().get("lookup/lead_column_filter").then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Lead columns fetched successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching lead columns."),
      data: {}
    };
  });
}

export const getLeadColumnMasterList = (masterId) => {
  return api().get(`lookup/lead/master/${masterId}/data`).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Column master list fetched successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching column master list."),
      data: {}
    };
  });
}

export const updateLeadColumnValue = (leadId, variables) => {
  return api().put(`/lead/${leadId}`, variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Lead updated successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while updating lead details."),
      data: {},
    };
  });
}

export const deleteSelectedLeads = (variables) => {
  return api().delete("/lead/delete", { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Lead deleted successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while deleting lead."),
      data: {}
    };
  });
}

export const getCampaignDataHistory = (variables) => {
  return api().options("/lead/upload/details", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Campaign dataHistory fetched successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching campaign data history."),
      data: {},
    };
  });
}

export const downloadLeadsFile = (url, resultAsBlob) => {
  return api().post(`/download/file?file_url=${url}&result_as_blob=${resultAsBlob}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Leads file downloaded successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while downloading leads file."),
      data: {}
    };
  });
}

export const fetchJobFunctionData = (variables) => {
  return api().options("/campaign/job_functions", { data: (variables || {}) }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "job functions data fetched successfully."),
      data: _get(res, "data.responseData", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching job functions data."),
      data: {},
    };
  });
};
